import { ColumnDef } from '@tanstack/react-table';

import { Item } from 'uikitv2/Dropdown/DropdownDnD/types';
import { Column as StoreColumn } from 'lib/features/tableSettings/types';
import { NewItem } from './types';

export const getItems = (columns: ColumnDef<any>[], storeColumns?: StoreColumn[]): Item[] => {
  if (!columns?.length) return [];
  if (storeColumns?.length) {
    const columnsIds: Record<string, string> = columns.reduce(
      (acc: Record<string, string>, { id, meta, header }) => (meta ? acc : { ...acc, [id as string]: header as string }),
      {},
    );
    const menu = storeColumns.reduce((acc: any, el: any) => {
      const { id, checked } = el;
      const item = columns.find((column) => column.id === id);
      return item?.header ? [
        ...acc,
        {
          id,
          checked,
          label: item?.header as string,
        }] : acc;
    }, []);
    const newItems = Object.entries(columnsIds).reduce((acc: NewItem[], [k, v]) => {
      const pos = menu.findIndex(({ id }) => id === k);
      const newPos = columns.findIndex(({ id }) => id === k);
      return (
        pos !== -1
          ? acc
          : [...acc, {
            id: k,
            checked: true,
            label: v,
            pos: newPos,
          }]
      );
    }, []);
    if (newItems.length) {
      newItems.forEach((value) => {
        const { pos, ...item } = value;
        menu.splice(pos, 0, item);
      });
      return menu;
    }
    return menu;
  }
  return columns.reduce(
    (acc, item) => (item?.id && item.header
      ? [...acc, { id: item.id, label: item.header as string, checked: true }]
      : acc),
    [] as Item[],
  );
};