import {
  memo, useCallback, useMemo, useState,
} from 'react';

import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { SquareButton } from 'uikitv2/Buttons/SquareButton';
import { Icon } from 'uikitv2/Icon';
import { DropdownDnD } from 'uikitv2/Dropdown/DropdownDnD';
import { updateTableColumns } from 'lib/features/tableSettings';
import { columnsTableSelector } from 'lib/features/tableSettings/selectors';
import { getItems } from './helpers';
import { TableConfiguratorProps } from './types';

export const TableConfigurator = memo<TableConfiguratorProps>(({
  columns, table, className, classNameDropdownToggle, classNameDropdownMenu,
}) => {
  const dispatch = useAppDispatch();
  const storeColumns = useAppSelector(columnsTableSelector(table));
  const items = useMemo(() => getItems(columns, storeColumns), [columns, storeColumns]);
  const onChange = useCallback((items) => {
    dispatch(updateTableColumns({ table, columns: items }));
  }, [dispatch, table]);
  const [active, setActive] = useState(false);
  const onToggle = useCallback((nextShow: boolean) => {
    setActive(nextShow);
  }, []);

  return (
    <DropdownDnD
      items={items}
      onChange={onChange}
      className={className}
      classNameDropdownMenu={classNameDropdownMenu}
      classNameDropdownToggle={classNameDropdownToggle}
      align="end"
      onToggle={onToggle}
      label={(
        <SquareButton
          variant="obscure"
          size="large"
          active={active}
          data-testid="button-column-properties"
        >
          <Icon name="settings" width={16} />
        </SquareButton>
      )}
    />
  );
});

export default TableConfigurator;
