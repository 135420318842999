import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Column as StoreColumn } from 'lib/features/tableSettings/types';

function getVisibleIdItem<Column>(visibleIdItems: string[], id: string, columns?: ColumnDef<Column>[]) {
  return (
    visibleIdItems.indexOf(id) < 0 ? (columns ?? []).length : visibleIdItems.indexOf(id)
  );
}

// todo add global interface { show: boolean } to ColumnMeta
function useTableSettings<Column>(
  columns: ColumnDef<Column, any>[] | undefined,
  storeColumns: StoreColumn[] = [],
): ColumnDef<Column, any>[] {
  return useMemo(() => {
    if (!columns) return [];
    if (!storeColumns.length) return columns;
    const newColumns: string[] = columns
      .reduce((acc: string[], { id, meta }) => (meta ? acc : [...acc, id as string]), [])
      .filter((item) => !storeColumns.find(({ id }) => item === id));
    const visibleIdItems: string[] = storeColumns
      .filter(({ checked }) => checked)
      .map(({ id }) => id);
    if (newColumns.length) {
      newColumns.forEach((value) => {
        const pos = columns.findIndex(({ id }) => id === value);
        visibleIdItems.splice(pos, 0, value);
      });
    }
    return columns
      .filter(({ id, meta }) => (
        visibleIdItems.includes(id as string) || (meta as { show: boolean; })?.show
      ))
      .sort((a, b) => {
        return (
          getVisibleIdItem(visibleIdItems, a.id as string, columns) - getVisibleIdItem(visibleIdItems, b.id as string, columns)
        );
      });
  }, [columns, storeColumns]);
}

export default useTableSettings;